import { Link } from "gatsby-plugin-react-i18next";
import React, { useRef, useState } from "react";

const DropdownLinks = ({ PagesGroupName, PageLinks }) => {
    const [active, setActive] = useState("");
    const [height, setHeight] = useState("0px")

    const content = useRef(null);

    function toggleAccordion() {
        setActive(active === "" ? "-active" : "");
        setHeight(active === "-active" ? "0px" : `${content.current.scrollHeight}px`)

        console.log(content.current.scrollHeight);
    };

    return (
        <div className={`m-nav__dropdown ${active}`}>
            <span onClick={toggleAccordion}>{PagesGroupName} <span className="-plus"></span></span>
            <div ref={content} style={{ maxHeight: `${height}` }} className="m-nav__dropdown--list">
                {PageLinks.map(({ linkText, linkUrl }, key) => (
                    <Link to={linkUrl} key={key}>{linkText}</Link>
                ))}

            </div>
        </div>
    );
};

export default DropdownLinks;
