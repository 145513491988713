import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Helmet } from "react-helmet";
import Favicon from "../../assets/images/global/favicon.png";



const query = graphql`
  {
    site {
      siteMetadata {
        siteDescription: description
        siteTitle: title
      }
    }
  }
`;

const SEO = ({ title, description }) => {
  const { site } = useStaticQuery(query);

  const { siteTitle, siteDescription } = site.siteMetadata;

  return (
    <Helmet
      title={`${title} | ${siteTitle}`}
      link={[
        { rel: 'shortcut icon', type: 'image/png', href: `${Favicon}` }
      ]}>
      <meta name="description" content={description || siteDescription}></meta>
    </Helmet>
  );
};

export default SEO;
