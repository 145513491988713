import React, { Fragment, useState } from "react";
import Logo from '../global/logo'
import IconPaperclip from '../../assets/images/global/paperclip.svg'
import { Link } from "gatsby-plugin-react-i18next";
import axios from 'axios';
import { useTranslation } from "react-i18next";

const isNullOrWhitespace = value => {
    if (!value || value.trim() === '') {
        return true;
    }
    return false;
}

const emailRegex = /^[a-zA-Z0-9.!#$%&"*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;


const Contact = ({ setOpenModal }) => {
    const { t } = useTranslation()

    const [step, setStep] = useState(1);
    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [email, setEmail] = useState('');
    const [topic, setTopic] = useState('');
    const [message, setMessage] = useState('');
    const [file, setFile] = useState('');
    const [phone, setPhone] = useState('')
    const [mailSent, setMailSent] = useState(false);
    const [error, setError] = useState(null);
    const [hasError, setHasError] = useState(false);
    const formValues = { name, company, email, topic, message, phone };



    const handleNextClick = () => {
        if (isNullOrWhitespace(name) || isNullOrWhitespace(email) || !RegExp(emailRegex).test(email)) {
            setHasError(true);
        } else {
            setStep(step + 1)
        }
    }

    const API_PATH = '/api/contact/index.php';

    const handleFormSubmit = e => {
        console.log('handleavoao sam');
        e.preventDefault();
        handleNextClick();
        axios({
            method: 'post',
            url: `${API_PATH}`,
            headers: { 'content-type': 'application/json' },
            data: formValues
        })
            .then(result => {
                setMailSent(result.data.sent)
                console.log(result);

            })
            .catch(error => setError(error.message));
    };

    const renderStep = () => {

        switch (step) {
            case 1:
                return (
                    <Fragment>
                        <h2 className="m-contact__heading">{t('contact.reachYou')}</h2>
                        <div className="m-contact__form">
                            <div className="m-contact__inputGroup">
                                <input type="text" placeholder={t('contact.yourName')} value={name} onChange={(e) => setName(e.target.value)} />
                                {hasError && isNullOrWhitespace(name) && <span className="m-contact__inputGroup--error">{t('contact.errorName')}</span>}
                            </div>
                            <div className="m-contact__inputGroup">
                                <input type="text" placeholder={t('contact.yourCompany')} value={company} onChange={(e) => setCompany(e.target.value)} />
                            </div>
                            <div className="m-contact__inputGroup">
                                <input type="text" placeholder={t('contact.yourPhone')} value={phone} onChange={(e) => setPhone(e.target.value)} />
                            </div>
                            <div className="m-contact__inputGroup">
                                <input type="email" placeholder={t('contact.yourEmail')} value={email} onChange={(e) => setEmail(e.target.value)} />
                                {hasError && (isNullOrWhitespace(email) || !RegExp(emailRegex).test(email)) && <span className="m-contact__inputGroup--error">{t('contact.errorEmail')}</span>}
                            </div>
                        </div>
                    </Fragment>
                )

            case 2:
                return (
                    <Fragment>
                        <h2 className="m-contact__heading">{t('contact.discuss')}</h2>
                        <div className="m-contact__form">
                            <div className="m-contact__radioGroup">
                                <input type="radio" id="developApp" name="topic" value="Develop an application" onChange={(e) => setTopic(e.target.value)} />
                                <label htmlFor="developApp">{t('contact.developApp')}</label>
                            </div>
                            <div className="m-contact__radioGroup">
                                <input type="radio" id="hireTeam" name="topic" value="Hire a team" onChange={(e) => setTopic(e.target.value)} />
                                <label htmlFor="hireTeam">{t('contact.hireTeam')}</label>
                            </div>
                            <div className="m-contact__radioGroup">
                                <input type="radio" id="digitalisation" name="topic" value="Digitalisation within my company" onChange={(e) => setTopic(e.target.value)} />
                                <label htmlFor="digitalisation">{t('contact.digitalisation')}</label>
                            </div>
                            <div className="m-contact__radioGroup">
                                <input type="radio" id="reeng" name="topic" value="Re-engineering of my application" onChange={(e) => setTopic(e.target.value)} />
                                <label htmlFor="reeng">{t('contact.reEng')}</label>
                            </div>
                            <div className="m-contact__radioGroup">
                                <input type="radio" id="otherServices" name="topic" value="It Services" onChange={(e) => setTopic(e.target.value)} />
                                <label htmlFor="otherServices">{t('contact.services')}</label>
                            </div>
                            <div className="m-contact__radioGroup">
                                <input type="radio" id="other" name="topic" value="Other" onChange={(e) => setTopic(e.target.value)} />
                                <label htmlFor="other">{t('contact.other')}</label>
                            </div>
                        </div>
                    </Fragment>
                )

            case 3:
                return (
                    <Fragment>
                        <h2 className="m-contact__heading">{t('contact.project')}</h2>
                        <div className="m-contact__form">
                            <div className="m-contact__inputGroup">
                                <textarea placeholder={t('contact.requirements')} value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
                            </div>
                            {/* <div className="m-contact__inputGroup">
                                <label htmlFor="attach-file" className="m-contact__inputGroup--fileLabel">
                                    {file ? <span>{file}</span> : "ATTACH A DOCUMENT"}
                                    <IconPaperclip />
                                </label>
                                <input id="attach-file" type="file" className="m-contact__inputGroup--fileInput" onChange={(e) => setFile(e.target.value)} />
                            </div> */}
                        </div>
                    </Fragment>
                )

            case 4:
                return (
                    <Fragment>
                        <div className="m-contact__finishedSteps">
                            <h2 className="-bigHeader">{t('contact.thankYou')}</h2>
                            <p>{t('contact.reciveInfo')}</p>
                        </div>
                    </Fragment>
                )
            default:
                return;
        }
    }



    return (
        <div className={`m-contact ${step === 4 ? "-lastStep" : ""}`}>
            <div className="m-contact__logo">
                <Logo />
            </div>
            <div className="m-contact__close" onClick={() => setOpenModal(false)}></div>
            <div className="_wr">
                <div className="_w">
                    <div className={`_12 ${step !== 4 ? "_s10 ofs_s1 ofs_l2" : ""}`} >
                        <div className="m-contact__step">
                            {step < 4 &&
                                <div className="m-contact__num">
                                    <span>{`0${step}`}</span>
                                    /04
                                </div>
                            }
                            {renderStep()}
                            <div className="m-contact__arrows">
                                {step >= 2 && step !== 4 && <span className="a-btn -redBorder a-hover -effectThree -toLeft" onClick={() => { setStep(step - 1) }}>
                                    {t('contact.back')}
                                    <span className="a-arrow -long"></span>
                                </span>}
                                {step <= 2 && <span className="a-btn -redBorder a-hover -effectThree -toRight" onClick={handleNextClick}>
                                    {t('contact.next')}
                                    <span className="a-arrow -long"></span>
                                </span>}
                                {step === 3 && <button type="submit" className="a-btn -redBorder a-hover -effectThree -toRight" onClick={e => handleFormSubmit(e)}>
                                    {t('contact.submit')}
                                    <span className="a-arrow -long"></span>
                                </button>}
                                {step === 4 && <Link to="/" className="a-btn -redBorder a-hover -effectThree -toRight" onClick={() => setOpenModal(false)}>
                                    {t('contact.backToHome')}
                                </Link>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default Contact;
