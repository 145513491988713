import React, { useContext, useEffect, useState } from "react";
import Logo from '../global/logo'
import { Link, useI18next, useTranslation } from 'gatsby-plugin-react-i18next'
import withModalContext, { ModalContext } from "../../context/ModalContext";
import DropdownLinks from "./dropdown-links";

const Navigation = ({ openModal, setOpenModal }) => {
  // const {openModal, setOpenModal} = useContext(ModalContext)
  const { languages, originalPath, i18n } = useI18next()
  const { t } = useTranslation()

  const [mobile, setMobile] = useState(false);
  const [scroll, setScroll] = useState(false);


  const handleClick = () => setMobile(!mobile);

  const onScroll = () => {
    setScroll(window.scrollY > 50);
    setMobile(false);
  };

  useEffect(() => {
    onScroll();
    window.addEventListener("scroll", onScroll, true);
    window.addEventListener("resize", () => setMobile(false));

    return () => {
      window.removeEventListener("scroll", onScroll, true);
      window.removeEventListener("resize", () => setMobile(false));
    };
  }, []);

  const displayLanguageName = (language) => {
    if (language === "en") {
      return "English";
    }
    if (language === "de") {
      return "Deutsch";
    }
  }

  const handleOpenModal = () => {
    setOpenModal(!openModal)
  }


  const marketsLinks = [
    {
      linkText: `${t('markets.eCommerce')}`,
      linkUrl: '/e-commerce'
    },
    {
      linkText: `${t('markets.marketing')}`,
      linkUrl: '/marketing'
    },
    {
      linkText: `${t('markets.industrials')}`,
      linkUrl: '/industrials'
    },
    {
      linkText: `${t('markets.realEstate')}`,
      linkUrl: '/real-estate'
    },
  ];
  const servicesLinks = [
    {
      linkText: `${t('navigation.services.appDev')}`,
      linkUrl: '/application-development'
    },
    {
      linkText: `${t('navigation.services.engTeam')}`,
      linkUrl: '/engineering-team'
    },
    {
      linkText: `${t('navigation.services.reEng')}`,
      linkUrl: '/application-re-engineering'
    },
    {
      linkText: `${t('navigation.services.otherServices')}`,
      linkUrl: '/other-services'
    },
  ];
  return (
    <nav className={`m-nav ${scroll ? "-scrolled" : ""} ${mobile ? "-mobile" : ""}`} >
      <div className="_w"></div>
      <div className="m-nav__content">
        <div className="_wr">
          <div className="m-nav__contentContainer">
            <div className="m-nav__logo">
              <Logo />
            </div>
            <div className="m-nav__right">
              <div className="m-nav__links">
                <DropdownLinks PagesGroupName={t('markets.markets')} PageLinks={marketsLinks} />
                <DropdownLinks PagesGroupName={t('navigation.services.itServices')} PageLinks={servicesLinks} />
                {/* <div className="m-nav__dropdown">
                  <span>{t('navigation.products.products')}</span>
                  <div className="m-nav__dropdown--list">
                    <Link to="#" >{t('navigation.products.fLeader')}</Link>
                    <Link to="#" >{t('navigation.products.eCommerce')}</Link>
                  </div>
                </div> */}
                <Link to="/our-work" >{t('navigation.ourWork')}</Link>
                <Link to="/about-us">{t('navigation.aboutUs')}</Link>
                {/* <Link to="/careers" className="-lastLink">Careers</Link> */}
                <span className="a-btn -blackBorder a-hover -effectThree -nav" onClick={handleOpenModal}>
                  {t('buttons.scheduleCall')}
                </span>
              </div>
              <div className="m-nav__lang">
                {languages.map((lng) => (
                  <Link to={originalPath} language={lng} className={`${lng === i18n.language ? '-active' : ''}`} key={lng} title={displayLanguageName(lng)}>
                    {lng}
                  </Link>
                ))}
              </div>
            </div>
            <div className="m-nav__hamburger"
              onClick={handleClick}
              onKeyPress={handleClick}
              role="menu"
              tabIndex={0}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default withModalContext(Navigation);
